import Page from "src_common/components/Page";
import { Button, Container, FormControl, IconButton, Stack, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import YaoTable from "src_common/components/yao-table";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { deleteDepartment, Department, DepartmentQueryRequest, getDepartments } from "src_lawfirm/api/departments";
import { useInfiniteScrollQuery } from "src_common/hooks/useInfiniteScrollQuery";
import _ from "lodash"
import { useSnackbar } from "notistack";
import useDebounce from "src_common/hooks/useDebounce";
import NiceModal from "@ebay/nice-modal-react";
import DepartmentDialog from "./DepartmentDialog";
import CustomIcon from "src_common/components/custom-icon";
import palette from "src_common/theme/palette";
import Iconify from "src_common/components/Iconify";
import { fDate } from "src_common/utils/formatTime";

export const defaultFilters: DepartmentQueryRequest = {
  title: '',
  limit: 20,
  page: 0
}

export default function DepartmentsTab() {
  const { debounce } = useDebounce()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const [filters, setFilters] = useState<DepartmentQueryRequest>(defaultFilters)
  const [inputValue, setInputValue] = useState<string>("")

  useEffect(() => {
    debounce(() => {
      handleTitleFilter(inputValue)
    }, 500)
  }, [inputValue])

  const departmentsQuery = useInfiniteQuery(
    ["departments", filters],
    ({ pageParam = 0 }) => getDepartments({
      ...filters,
      page: pageParam,
    }),
    {
      getNextPageParam: (lastPage, allPages) =>
        Array.isArray(lastPage) && !lastPage.length
          ? undefined
          : allPages.length
    }
  )

  const departmentInfiniteQuery = useInfiniteScrollQuery({
    fetch:
      !departmentsQuery.isFetching && departmentsQuery.hasNextPage
        ? departmentsQuery.fetchNextPage
        : undefined,
  })

  const refetchQuery = () => {
    queryClient.invalidateQueries(["departments", filters])
  }

  const rows = useMemo(() => {
    return (departmentsQuery.data?.pages || [])
      .flatMap((page) => page)
  }, [departmentsQuery.data])

  const updateFilter = (key: keyof DepartmentQueryRequest, value: any) => {
    setFilters((prev) => ({ ...prev, [key]: value }))
  }

  const handleTitleFilter = (value: string) => {
    updateFilter("title", value.trim())
  }

  const handleAddDepartment = () => {
    NiceModal.show(DepartmentDialog, {
    }).then(() => {
      refetchQuery()
    })
  }

  const handleEditDepartment = (department: Department) => {
    NiceModal.show(DepartmentDialog, {
      department,
    }).then(() => {
      refetchQuery()
    })
  }

  const handleRemoveDepartment = async (department: Department) => {
    try {
      await deleteDepartment(department._id)
      enqueueSnackbar(`Department ${department.title} removed`)
      refetchQuery()
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" })
    }
  }

  const TableToolbar = ({ inputValue, setInputValue }: any) => (
    <>
      <Button
        startIcon={
          <CustomIcon
            name="add"
            sx={{ padding: 0.2 }}
            color={palette.yao.grey[6]}
          />
        }
        variant="outlined"
        onClick={() => handleAddDepartment()}
      >
        Department
      </Button>
      <FormControl>
        <TextField
          id="title-search"
          label="Search by title"
          variant="outlined"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value)
          }}
          InputProps={{
            sx: {
              p: 0,
              padding: 0,
              width: "20rem",
              height: "2.5rem"
            }
          }}
        />
      </FormControl>
      {(inputValue.length > 0) && (
        <Tooltip title="Clear title">
          <IconButton
            onClick={() => {
              setInputValue("")
            }}
          >
            <Iconify icon="cil:filter-x" />
          </IconButton>
        </Tooltip>
      )}
    </>
  )

  const columns = [
    {
      id: "title",
      label: "title",
      formatter: (__: string, row: Department) => (_.capitalize(row?.title)),
    },
    {
      id: "short_description",
      label: "description",
      formatter: (__: any, row: Department) => {
        return (
          <Typography noWrap={true} maxWidth={250} title={row?.short_description}>
            {row?.short_description || "-"}
          </Typography>
        )
      },
    },
    {
      id: "created_at",
      label: "created at",
      formatter: fDate
    },
    {
      id: "",
      label: "",
      sx: { width: "1rem" },
    }
  ]

  return (
    <Page title="Departments" sx={{ height: "100%" }}>
      <Container maxWidth={false} sx={{ height: "100%" }}>
        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} sx={{ marginTop: "0.5rem" }}>
          <Typography sx={{ fontSize: 18, fontWeight: 600 }}>Departments</Typography>
          <Stack direction="row" spacing={1} justifyContent={"flex-end"} alignItems={"center"}>
            {TableToolbar({ inputValue, setInputValue, handleTitleFilter })}
          </Stack>
        </Stack>
        <YaoTable
          isLoading={departmentsQuery.isLoading}
          isPagging={departmentsQuery.isFetching}
          afterRows={() => (
            <>
              <TableRow ref={departmentInfiniteQuery.ref} style={{ height: 0 }} />
            </>
          )}
          labelNoData="No departments found"
          imgNoData="/assets/illustrations/illustration_empty_content.svg"
          rows={rows}
          columns={columns as any}
          rowOptions={[
            {
              action: handleEditDepartment,
              label: "Edit",
              icon: "eva:edit-fill"
            },
            {
              action: handleRemoveDepartment,
              label: "Remove",
              icon: "eva:trash-2-outline"
            }
          ]}
        />
      </Container>
    </Page>
  )
}