import { CircularProgress } from "@mui/material";
import { Backdrop, Button, Grid, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormProvider } from "src_common/components/hook-form";
import { YAOFieldAutocomplete, YAOFieldText } from "src_common/components/yao-form-fields";
import { YaoFormFieldLabel } from "src_common/components/yao-form/YaoForm";
import palette from "src_common/theme/palette";
import { formatError } from "src_common/utils/misc";
import { CaseType, CaseTypeFormData, createCaseType, getCaseType, updateCaseType } from "src_lawfirm/api/case-types";
import { Department } from "src_lawfirm/api/departments";

type CaseTypeFormProps = {
  type: "create" | "update";
  case_type?: CaseType;
  onFinish: (caseType: CaseType) => void;
  onCancel: () => void;
  confirmText: string;
  departments: Department[];
}

type CaseTypeFormModel = {
  title: string;
  short_description: string | null;
  department: {
    _id: string;
    title: string;
  };
}

const DEFAULT_FORM_VALUES: CaseTypeFormModel = {
  title: "",
  short_description: null,
  department: {
    _id: "",
    title: "",
  },
}

const CaseTypeForm: FC<CaseTypeFormProps> = ({ type, case_type, onFinish, onCancel, confirmText, departments }) => {
  const [loader, setLoader] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm<CaseTypeFormModel>({
    mode: "onChange",
    defaultValues: DEFAULT_FORM_VALUES,
  })

  useEffect(() => {
    if (type === "update" && case_type) {
      (async () => {
        setLoader(true)
        try {
          const response = await getCaseType(case_type._id)
          methods.reset({
            title: response?.title || "",
            short_description: response?.short_description || "",
            department: response?.department || { _id: "", title: "" },
          })
        } catch (e) {
          enqueueSnackbar("Try again", { variant: "error" })
          handleCancel()
        } finally {
          setLoader(false)
        }
      })()
    }
  }, [])

  const handleCancel = () => onCancel && onCancel();

  const onSubmit: SubmitHandler<CaseTypeFormModel> = async (data) => {
    try {
      setLoader(true)

      const caseTypeDto: CaseTypeFormData = {
        title: (data.title || '').trim(),
        short_description: (data.short_description || "").trim(),
        department: data.department._id,
      }
      let response: CaseType | null = null
      if (type === "create") {
        response = await createCaseType(caseTypeDto)
        enqueueSnackbar("Case type created successfully")
      } else if (type === "update") {
        response = await updateCaseType(case_type?._id || "", caseTypeDto)
        enqueueSnackbar("Case type updated successfully")
      } else {
        throw new Error("Invalid type")
      }

      if (response !== null && response !== undefined) {
        onFinish && onFinish(response)
      } else {
        throw new Error("Invalid response")
      }
    } catch (error) {
      enqueueSnackbar(formatError(error.data || error), { variant: "error" })
    } finally {
      setLoader(false)
    }
  }

  return (
    <>
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <Typography variant="h2">
          {type === "create" ? "Create" : "Update"}{' case type'}
        </Typography>
        <Grid container rowSpacing={1.5} columnSpacing={2.5}>
          <Grid item xs={12} md={12}>
            <YaoFormFieldLabel name="department" label="Department" required />
            <YAOFieldAutocomplete
              name="department"
              placeholder="Choose department"
              options={departments}
              getOptionLabel={(v) => v.title}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <YaoFormFieldLabel name="title" label="Title" required />
            <YAOFieldText
              name="title"
              type="text"
              placeholder="Enter title"
              rules={{
                required: "Title is required"
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <YaoFormFieldLabel name="short_description" label="Description" />
            <YAOFieldText
              name="short_description"
              type="text"
              placeholder="Enter description"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} mt={2}>
          <Typography sx={{ fontSize: 11, color: palette.yao.primary[3] }}>
            <span style={{ color: palette.yao.secondary[2], fontWeight: 600 }}>
              *
            </span>{" "}
            Mandatory fields
          </Typography>
        </Grid>
        <Stack
          direction="row"
          sx={{
            mt: 4,
            justifyContent: "right",
          }}
          spacing={2}
        >
          <Button color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ minWidth: 100 }}
            type="submit"
            onClick={methods.handleSubmit(onSubmit)}
          >
            {confirmText}
          </Button>
        </Stack>
      </FormProvider >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default CaseTypeForm