import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Dialog } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Department } from "src_lawfirm/api/departments";
import DepartmentForm from "./DepartmentForm";

type DialogProps = {
  department?: Department
}

const DepartmentDialog = NiceModal.create<DialogProps>(({ department }) => {
  const modal = useModal();
  const [type, setType] = useState<"create" | "update">("create");

  useEffect(() => {
    if (department !== null && department !== undefined) {
      setType("update");
    }
  }, [department])

  const handleCloseForm = useCallback(() => {
    modal.hide()
  }, [modal])

  const handleFinishForm = (created: Department) => {
    modal.resolve(created)
    modal.hide()
  }

  return (
    <>
      <Dialog
        maxWidth="md"
        open={modal.visible}
        onClose={handleCloseForm}
        TransitionProps={{
          onExited: () => modal.remove()
        }}
      >
        <div style={{ padding: "2rem" }}>
          <DepartmentForm
            type={type}
            department={department}
            onCancel={handleCloseForm}
            onFinish={handleFinishForm}
            confirmText={type === "create" ? "Add" : "Save"}
          />
        </div>
      </Dialog>
    </>
  )
})

export default DepartmentDialog;