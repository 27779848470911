import {
  FormControlLabel,
  Grid,
  InputAdornment,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { CurrencyMask } from "src_common/components/yao-form";
import {
  YAOFieldAutocomplete,
  YAOFieldText,
} from "src_common/components/yao-form-fields";
import { YaoFormFieldLabel } from "src_common/components/yao-form/YaoForm";
import { useAPI } from "src_common/hooks/useAPI";
import palette from "src_common/theme/palette";
import { getAttorneys } from "src_lawfirm/api/attorneys";
import { getCasetype } from "src_lawfirm/api/case-types";
import { getDepartments } from "src_lawfirm/api/departments";
import { Source, SourceLabel } from "src_lawfirm/api/matters";
import { LawFirmContext } from "../../law-firm/LawFirmContext";
import Iconify from "src_common/components/Iconify";
import { MatterDetailsContext } from "../context/matter-details-context";

export const GeneralFields = () => {
  const lawFirmContext = useContext(LawFirmContext);
  const { interests } = useContext(MatterDetailsContext);
  const { watch, resetField, setValue } = useFormContext();
  const department = watch("department");
  const isPrivate = watch("private");
  const responsible_lawyer = watch("responsible_lawyer");
  const rate = watch("rate");
  const [permissions, _setPermissions] = useState({
    me: false,
    everyone: false,
    custom: false,
  });

  const interestOptions = useMemo(() => {
    return (
      interests?.map((interest) => ({
        value: interest._id,
        label: `${interest.title} - ${interest.value}%`,
      })) ?? []
    );
  }, [interests]);

  const setPermissions = (key: keyof typeof permissions, value: boolean) => {
    _setPermissions((prev) => ({ ...prev, [key]: value }));
  };

  const departmentAPI = useAPI(getDepartments);
  const caseTypesAPI = useAPI(getCasetype);
  const attorneysAPI = useAPI(getAttorneys);

  useEffect(() => {
    departmentAPI.invoke();
    attorneysAPI.invoke();
  }, []);

  useEffect(() => {
    if (department?._id) {
      resetField("case_type");
      caseTypesAPI.invoke(department._id);
    }
  }, [department?._id]);

  useEffect(() => {
    if (responsible_lawyer?._id) {
      setValue("rate", rate || responsible_lawyer?.rate);
    }
  }, [responsible_lawyer?._id]);

  return (
    <Grid container rowSpacing={1.5} columnSpacing={2.5}>
      <Grid item xs={12}>
        <YaoFormFieldLabel name="case_name" label="Matter Name" required />
        <YAOFieldText name="case_name" placeholder="Enter a matter name" />
      </Grid>

      <Grid item xs={6}>
        <YaoFormFieldLabel name="department" label="Department" required />
        <YAOFieldAutocomplete
          name="department"
          placeholder="Choose Department"
          options={departmentAPI.data || []}
          getOptionLabel={(v) => v.title}
          loading={departmentAPI.loading}
        />
      </Grid>
      <Grid item xs={6}>
        <YaoFormFieldLabel name="case_type" label="Matter Type" required />
        <YAOFieldAutocomplete
          name="case_type"
          placeholder="Choose matter type"
          options={caseTypesAPI.data || []}
          getOptionLabel={(v) => v.title}
          loading={caseTypesAPI.invoked && caseTypesAPI.loading}
          disabled={!department?._id}
        />
      </Grid>

      <Grid item xs={6}>
        <YaoFormFieldLabel
          name="responsible_lawyer"
          label="Responsible Lawyer"
          required
        />
        <YAOFieldAutocomplete
          name="responsible_lawyer"
          options={(attorneysAPI?.data || []).map((a) => ({
            ...a,
            changeVal: a.rate,
          }))}
          getOptionLabel={(a) => `${a.name} ${a.surname ?? ""}`}
          placeholder="Choose lawyer"
          customOnChange={(rate) => {
            resetField("rate");
            setValue("rate", rate);
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <YaoFormFieldLabel
          name="responsible_supervisor"
          label="Responsible Supervisor"
          required
        />
        <YAOFieldAutocomplete
          name="responsible_supervisor"
          options={attorneysAPI?.data || []}
          getOptionLabel={(a) => `${a.name} ${a.surname ?? ""}`}
          placeholder="Choose responsible supervisor"
        />
      </Grid>

      <Grid item xs={6}>
        <YaoFormFieldLabel name="paralegal" label="Paralegal/Assistant" />
        <YAOFieldAutocomplete
          name="paralegal"
          options={attorneysAPI?.data || []}
          getOptionLabel={(a) => `${a.name} ${a.surname ?? ""}`}
          placeholder="Choose paralegal/assistant"
        />
      </Grid>
      <Grid item xs={6}>
        <YaoFormFieldLabel name="secretary" label="Secretary/Admin" />
        <YAOFieldAutocomplete
          name="secretary"
          options={attorneysAPI?.data || []}
          getOptionLabel={(a) => `${a.name} ${a.surname ?? ""}`}
          placeholder="Choose secretary/admin"
        />
      </Grid>

      <Grid item xs={12} sx={{ mt: 2 }} />

      <Grid item xs={3}>
        <YaoFormFieldLabel name="rate" label="Override default rate" />

        <YAOFieldText
          name="rate"
          placeholder="Value"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {lawFirmContext.getCurrencySymbol()}/hour
              </InputAdornment>
            ),
            inputComponent: CurrencyMask as any,
          }}
          disabled={!responsible_lawyer?._id}
        />
      </Grid>
      <Grid item xs={3}>
        <YaoFormFieldLabel name="financial_limit" label="Add financial limit" />

        <YAOFieldText
          name="financial_limit"
          placeholder="Enter value"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {lawFirmContext.getCurrencySymbol()}
              </InputAdornment>
            ),
            inputComponent: CurrencyMask as any,
          }}
        />
      </Grid>
      {/* TODO: Uncomment when interests tab is ready */}
      {/* <Grid item xs={3}>
        <YaoFormFieldLabel name="interest_rate" label="Matter Interest Rate" />
        <YAOFieldAutocomplete
          getOptionLabel={(v) => {
            return v.label;
          }}
          options={interestOptions}
          name="interest_rate"
          placeholder="Interest Rate"
        />
      </Grid> */}

      <Grid item xs={3}>
        <Tooltip title="Private matters can only be viewed by the responsible layer, supervisor, paralegal, secretary, and master users">
          <Stack direction="row" alignItems="center">
            <Iconify
              color={palette.yao.grey[6]}
              mr="5px"
              mb="2px"
              icon="material-symbols:info-outline"
            />
            <YaoFormFieldLabel name="private" label="Permission" />
          </Stack>
        </Tooltip>
        <FormControlLabel
          name="private"
          control={
            <Switch
              name="private"
              defaultChecked={isPrivate}
              onChange={(event) => setValue("private", event.target.checked)}
            />
          }
          label="Make it private"
        />
      </Grid>

      <Grid item xs={12} sx={{ mt: 2 }} />

      <Grid item xs={6}>
        <YaoFormFieldLabel
          name="source"
          label="Source of Client Introduction"
        />
        <YAOFieldAutocomplete
          name="source"
          placeholder="Please choose"
          options={Object.keys(SourceLabel).map((k) => ({
            label: SourceLabel[k as Source],
            value: k,
          }))}
        />
      </Grid>

      <Grid item xs={12}>
        <YaoFormFieldLabel
          name="client_letter_subject"
          label="Subject for client correspondence"
        />

        <YAOFieldText
          name="client_letter_subject"
          placeholder="Enter content"
          multiline
        />
      </Grid>

      <Grid item xs={12}>
        <YaoFormFieldLabel
          name="other_party_letter_subject"
          label="Subject for other party correspondence"
        />

        <YAOFieldText
          name="other_party_letter_subject"
          placeholder="Enter content"
          multiline
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="caption" color="text.secondary">
          <span style={{ color: palette.yao.secondary[2] }}>*</span> Mandatory
          fields
        </Typography>
      </Grid>
    </Grid>
  );
};
