import { FC, useState, useEffect, SyntheticEvent, useContext } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  Button,
  Stack,
  Typography,
  Grid,
  Backdrop,
  CircularProgress,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { FormProvider } from "src_common/components/hook-form";
import {
  ConflictType,
  Contact,
  ContactFormData,
  ContactType,
  createContact,
  editContact,
  getContact,
  getContacts,
  getContactsTags,
  SalutationTypes,
  searchCompany,
  UkApiResponse,
} from "src_lawfirm/api/contacts";
import {
  YAOFieldAutocomplete,
  YAOFieldAutocompleteOptionObject,
  YAOFieldDatePicker,
  YAOFieldText,
} from "src_common/components/yao-form-fields";
import palette from "src_common/theme/palette";
import {
  YAOFieldAutocompleteOption,
  YAOFieldAutocompleteTagInput,
} from "src_common/components/yao-form-fields/YAOFieldAutocomplete";
import { YaoFormFieldLabel } from "src_common/components/yao-form/YaoForm";
import useDebounce from "src_common/hooks/useDebounce";
import { formatError } from "src_common/utils/misc";
import NiceModal from "@ebay/nice-modal-react";
import ContactConflictDialog from "./ContactConflictDialog";
import { usePlacesWidget } from "react-google-autocomplete";
import "./contact.css";
import Iconify from "src_common/components/Iconify";
import { useAPI } from "src_common/hooks/useAPI";
import useConflicts from "./useConflicts";
import { LawFirmContext } from "../law-firm/LawFirmContext";
import { YaoFieldPhoneInput } from "../../../src_common/components/yao-form-fields/YaoFieldPhoneInput";

type ContactFormProps = {
  type: "create" | "update" | "embeded";
  contact?: Contact;
  onFinish?: (contact: Contact) => void;
  onCancel?: () => void;
  onEmbededFilled?: (model: ContactFormData) => void;
  cancelText?: string;
  confirmText?: string;
  company?: boolean;
  hideCancel?: boolean;
};

export type ContactFormModel = {
  salutation: string;
  occupation: string;
  title: YAOFieldAutocompleteOption | null;
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  mobile_phone: string | null;
  home_phone: string | null;
  work_phone: string | null;
  national_insurance_number: string;
  birth_date: Date | null;
  address_region: string;
  address_postal_code: string;
  address_premises: string;
  address_locality: string;
  address_address_line_1: string;
  address_address_line_2: string;
  address_country: string;
  address_state: string;
  address_city: string;
  company: YAOFieldAutocompleteOption | null;
  company_name: string;
  company_number: string;
  company_web_filling_code: string;
  company_utr: string;
  fax_number: string;
  website: string;
  beneficial_owner: string;
  directors: string[];
  tags: string[];
  force_create: boolean;
  notes: string;
  bank_account_account_sort_code: string;
  bank_account_account_number: string;
  bank_account_account_name: string;
  cc_emails: string[];
  reasons: { description: string; _id: string }[];
};

const DEFAULT_FORM_VALUES: ContactFormModel = {
  salutation: "",
  occupation: "",
  title: null,
  first_name: "",
  middle_name: "",
  last_name: "",
  email: "",
  mobile_phone: null,
  home_phone: null,
  work_phone: null,
  national_insurance_number: "",
  birth_date: null,
  address_region: "",
  address_postal_code: "",
  address_premises: "",
  address_locality: "",
  address_address_line_1: "",
  address_address_line_2: "",
  address_country: "",
  address_state: "",
  address_city: "",
  company: null,
  company_name: "",
  company_number: "",
  company_web_filling_code: "",
  company_utr: "",
  fax_number: "",
  website: "",
  beneficial_owner: "",
  directors: [],
  tags: [],
  force_create: false,
  notes: "",
  bank_account_account_sort_code: "",
  bank_account_account_number: "",
  bank_account_account_name: "",
  cc_emails: [],
  reasons: [],
};

const FORM_SALUTATIONS = Object.keys(SalutationTypes).map((k: string) => ({
  label: `${k}.`,
  value: k,
}));

const DEFAULT_SPACING = 2;

const ContactForm: FC<ContactFormProps> = ({
  type,
  contact,
  onFinish,
  onCancel,
  onEmbededFilled,
  cancelText = "Cancel",
  confirmText = "Save",
  company = false,
  hideCancel = false,
}) => {
  const lawFirmContext = useContext(LawFirmContext);
  const [isCompany, setIsCompany] = useState<boolean>(company);
  const companyApi = useAPI(getContacts);
  const [searchingCompany, setSearchingCompany] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { debounce, reset: clearDebounce } = useDebounce();
  const [options, setOptions] = useState<UkApiResponse[]>([]);
  const [option, setOption] = useState<UkApiResponse | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [lastSalutation, setLastSalutation] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);
  const [additionalInfoVisible, setAdditionalInfoVisible] =
    useState<boolean>(false);
  const methods = useForm<ContactFormModel>({
    mode: "onChange",
    defaultValues: DEFAULT_FORM_VALUES,
  });
  const { firstFetch: getConflicts } = useConflicts(type === "embeded");
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      if (
        !place ||
        !Array.isArray(place.address_components) ||
        !place.address_components.length
      ) {
        return;
      }
      let street = "";
      let number = "";
      let city = "";
      let postal_code = "";

      const findAdressIndex = (path: string): number =>
        place.address_components.findIndex(
          (i: { types: string[] }) =>
            Array.isArray(i.types) && i.types.indexOf(path) !== -1
        );

      let idx = findAdressIndex("route");
      if (idx !== -1) {
        street += `${place.address_components[idx].long_name}`;
      }
      idx = findAdressIndex("sublocality_level_1");
      if (idx !== -1) {
        street += `, ${place.address_components[idx].long_name}`;
      }

      idx = findAdressIndex("street_number");
      if (idx !== -1) {
        number += `${place.address_components[idx].long_name}`;
      }

      
      idx = findAdressIndex("locality");
      if (idx === -1) {
        idx = findAdressIndex("postal_town"); 
      }
      if (idx !== -1) {
        city = `${place.address_components[idx].long_name}`;
      }

      idx = findAdressIndex("postal_code");
      if (idx !== -1) {
        postal_code += `${place.address_components[idx].long_name}`;
      }

      methods.setValue("address_address_line_1", street, {
        shouldValidate: true,
      });
      methods.setValue("address_address_line_2", number, {
        shouldValidate: true,
      });
      methods.setValue("address_city", city, {
        shouldValidate: true,
      });
      methods.setValue("address_postal_code", postal_code, {
        shouldValidate: true,
      });
    },
    options: {
      fields: ["address_components", "geometry"],
      types: ["address"],
    },
  });
  const [companyInputValue, setCompanyInputValue] = useState<string>("");

  const { reset, setValue } = methods;
  const watchForDefaults = methods.watch(["first_name"]);

  useEffect(() => {
    if (type !== "embeded") {
      loadTags();
      loadCompanies();
    }
    if (type === "update" && (contact === null || contact === undefined)) {
      enqueueSnackbar("Try again", { variant: "error" });
      handleCancel();
    } else if (type === "update") {
      (async () => {
        setLoader(true);
        try {
          const response = await getContact(contact?._id || "");
          reset({
            salutation: response?.salutation || "",
            title:
              FORM_SALUTATIONS.find((s) => s.value === response?.title) || null,
            first_name: response?.first_name || "",
            middle_name: response?.middle_name || "",
            last_name: response?.last_name || "",
            email: response?.email || "",
            mobile_phone: response?.mobile_phone || "",
            national_insurance_number:
              response?.national_insurance_number || "",
            birth_date: !!response?.birth_date
              ? new Date(response?.birth_date)
              : null,
            address_region: response?.address?.region || "",
            address_postal_code: response?.address?.postal_code || "",
            address_premises: response?.address?.premises || "",
            address_locality: response?.address?.locality || "",
            address_address_line_1: response?.address?.address_line_1 || "",
            address_address_line_2: response?.address?.address_line_2 || "",
            address_country: response?.address?.country || "",
            address_state: response?.address?.state || "",
            address_city: response?.address?.city || "",
            company: !!response?.company
              ? {
                value: response.company._id,
                label: response.company.company_name,
              }
              : null,
            company_name: response?.company_name || "",
            company_number: response?.company_number || "",
            company_web_filling_code: response?.company_web_filling_code || "",
            company_utr: response?.company_utr || "",
            fax_number: response?.fax_number || "",
            website: response?.website || "",
            beneficial_owner: response?.beneficial_owner || "",
            directors: response?.directors || [],
            tags: response?.tags || [],
            occupation: response?.occupation || "",
            notes: response?.notes || "",
            bank_account_account_sort_code:
              response?.bank_account?.account_sort_code || "",
            bank_account_account_number:
              response?.bank_account?.account_number || "",
            bank_account_account_name:
              response?.bank_account?.account_name || "",
            cc_emails: Array.isArray(response?.cc_emails)
              ? response.cc_emails
              : [],
          });
        } catch (e) {
          enqueueSnackbar("Try again", { variant: "error" });
          handleCancel();
        } finally {
          setLoader(false);
        }
      })();
    }
  }, []);

  useEffect(() => {
    let active = true;
    clearDebounce();
    if (inputValue === "") {
      setOptions(option ? [option] : []);
      return undefined;
    }
    setSearchingCompany(true);
    debounce(async () => {
      try {
        const response = await searchCompany(inputValue);
        if (active) {
          let newOptions: UkApiResponse[] = [];
          if (option) {
            newOptions = [option];
          }
          if (response.length > 0) {
            newOptions = [...newOptions, ...response];
          }
          setOptions(newOptions);
        }
      } catch (error) {
        enqueueSnackbar(formatError(error), { variant: "error" });
      } finally {
        setSearchingCompany(false);
        clearDebounce();
      }
    }, 1000);

    return () => {
      active = false;
    };
  }, [option, inputValue]);

  useEffect(() => {
    const subscription = methods.watch((value, { name, type, ...rest }) => {
      if (type !== "change") {
        return;
      }
      if (name === "first_name") {
        if (value.salutation === lastSalutation) {
          setLastSalutation(value.first_name || "");
          setValue("salutation", value.first_name || "");
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watchForDefaults]);

  useEffect(() => {
    clearDebounce();
    if (!companyInputValue.length) {
      return;
    }
    debounce(() => {
      loadCompanies();
    }, 250);
  }, [companyInputValue]);

  const loadTags = () => {
    (async () => {
      const response = await getContactsTags();
      setTags(response);
    })();
  };

  const loadCompanies = () => {
    if (company) {
      return;
    }
    companyApi.invoke({
      type: ContactType.Company,
      search: companyInputValue?.length > 0 ? companyInputValue : undefined,
    });
  };

  const handleCancel = () => onCancel && onCancel();

  const handleConflict = (contacts: Contact[]) => {
    NiceModal.show<{ forcedUpdate: boolean; reasons?: { description: string; _id: string }[] }>(ContactConflictDialog, {
      contacts,
    }).then((response) => {
      if (response.forcedUpdate) {
        setValue("force_create", true);
        setValue("reasons", response?.reasons);
        methods.handleSubmit(onSubmit)();
      }
    });
  };

  function extractNumber<T = any>(
    value: string | null | undefined,
    defaultValue: T
  ): T {
    try {
      return !!value
        ? (value.match(/\d+/g)?.join("") as unknown as T)
        : (defaultValue as T);
    } catch (e) {
      return defaultValue as T;
    }
  }

  const onSubmit: SubmitHandler<ContactFormModel> = async (data) => {
    try {
      setLoader(true);

      const contactDto: ContactFormData = {
        title: data.title?.value || undefined,
        first_name: (data.first_name || '').trim(),
        middle_name: (data.middle_name || '').trim(),
        last_name: (data.last_name || '').trim(),
        email: `${data.email || ""}`.trim().length > 0 ? data.email.trim() : undefined,
        mobile_phone: extractNumber<string | undefined>(
          data.mobile_phone,
          undefined
        ),
        home_phone: extractNumber<string | undefined>(
          data.home_phone,
          undefined
        ),
        work_phone: extractNumber<string | undefined>(
          data.work_phone,
          undefined
        ),
        landline: undefined,
        address: {
          region: data.address_region,
          postal_code: data.address_postal_code,
          premises: data.address_premises,
          locality: data.address_locality,
          address_line_1: data.address_address_line_1,
          address_line_2: data.address_address_line_2,
          country: data.address_country,
          state: data.address_state,
          city: data.address_city,
        },
        birth_date: data.birth_date?.toISOString() || undefined,
        national_insurance_number: data.national_insurance_number,
        salutation: data.salutation || "",
        notes: data.notes || "",
        bank_account: {
          account_sort_code: data.bank_account_account_sort_code,
          account_number: data.bank_account_account_number,
          account_name: data.bank_account_account_name,
        },
        company: data.company?.value || null,
        company_name: data.company_name,
        company_number: data.company_number,
        company_web_filling_code: data.company_web_filling_code,
        company_utr: data.company_utr,
        fax_number: extractNumber<string>(data.fax_number, ""),
        website: data.website,
        directors: data.directors || [],
        tags: data.tags || [],
        beneficial_owner: data.beneficial_owner,
        type: isCompany ? ContactType.Company : ContactType.Person,
        force_create: data.force_create || false,
        occupation: data.occupation || "",
        cc_emails: data.cc_emails || [],
        reasons: data.reasons || [],
      };

      if (type === "embeded") {
        onEmbededFilled && onEmbededFilled(contactDto);
        return;
      }

      let response: Contact | null = null;
      switch (type) {
        case "create":
          response = await createContact(contactDto);
          enqueueSnackbar("Contact created successfully");
          if (data.force_create) {
            await getConflicts();
          }
          break;
        case "update":
          response = await editContact((contact as Contact)._id, {
            ...contactDto,
            type: undefined,
            force_create: undefined,
          });

          enqueueSnackbar("Contact updated successfully");
          break;
        default:
          throw new Error("Invalid type");
      }
      if (response !== null && response !== undefined) {
        onFinish && onFinish(response);
      } else {
        throw new Error("Invalid response");
      }
    } catch (error) {
      if (error?.type === "conflict") {
        handleConflict(error.data || []);
      } else {
        enqueueSnackbar(formatError(error.data || error), { variant: "error" });
      }
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {type !== "embeded" ? (
            <Typography variant="h2">
              {type === "update" ? "Update" : "Add new"}{" "}
              {isCompany ? "Company" : "Contact"}
            </Typography>
          ) : (
            <Typography variant="h2"></Typography>
          )}
        </Stack>
        <Grid container rowSpacing={1.5} columnSpacing={2.5}>
          {isCompany ? (
            <>
              <Grid item xs={12}>
                <YaoFormFieldLabel
                  name="company_name"
                  label="Company name"
                  required
                />
                {lawFirmContext.isUkCompany() ? (
                  <Autocomplete
                    id="company_name"
                    fullWidth
                    getOptionLabel={(o: UkApiResponse) => o.title}
                    filterOptions={(o) => o}
                    options={options}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={option || { title: methods.getValues("company_name") }}
                    onChange={(event: any, newValue: UkApiResponse | null) => {
                      setOptions((old) => (newValue ? [newValue, ...old] : old));
                      setOption(newValue);
                      if (newValue) {
                        setValue("company_name", newValue.title);
                        setValue("company_number", newValue.company_number || "");
                        setValue(
                          "address_address_line_1",
                          newValue.address_snippet || ""
                        );
                      }
                    }}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                      setValue("company_name", newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label=""
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {searchingCompany ? (
                                <CircularProgress color="inherit" size={16} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    renderOption={(props, opt) => {
                      return (
                        <li {...props}>
                          <Typography variant="body2" color="text.secondary">
                            {opt.title},{" "}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {opt.address_snippet || ""}
                          </Typography>
                        </li>
                      );
                    }}
                  />
                ) : (
                  <YAOFieldText
                    name="company_name"
                    label=""
                    type="text"
                    placeholder="Enter the company name"
                  />
                )}

              </Grid>
              <Grid item xs={12}>
                <YaoFormFieldLabel name="salutation" label="Lead Contact Name (for letters & emails)" />
                <YAOFieldText
                  name="salutation"
                  label=""
                  type="text"
                  placeholder="Enter lead contact name"
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <YaoFormFieldLabel name="title" label="Title" />
                <YAOFieldAutocomplete
                  name="title"
                  label=""
                  placeholder="Select title"
                  options={FORM_SALUTATIONS}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <YaoFormFieldLabel
                  name="first_name"
                  label="First name"
                  required
                />
                <YAOFieldText
                  name="first_name"
                  type="text"
                  placeholder="Enter first name"
                  rules={{
                    required: "First name is required",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <YaoFormFieldLabel name="middle_name" label="Middle name" />
                <YAOFieldText
                  name="middle_name"
                  label=""
                  type="text"
                  placeholder="Enter middle name"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <YaoFormFieldLabel
                  name="last_name"
                  label="Last name"
                  required
                />
                <YAOFieldText
                  name="last_name"
                  label=""
                  type="text"
                  placeholder="Enter last name"
                  rules={{
                    required: "Last name is required",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <YaoFormFieldLabel name="salutation" label="Preferred Name" />
                <YAOFieldText
                  name="salutation"
                  label=""
                  type="text"
                  placeholder="Enter preferred name"
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} md={6} marginTop={DEFAULT_SPACING}>
            <YaoFormFieldLabel name="email" label="Email" />
            <YAOFieldText
              name="email"
              label=""
              type="email"
              placeholder="Enter your email address"
            />
          </Grid>
          <Grid item xs={12} md={6} marginTop={DEFAULT_SPACING}>
            <YaoFormFieldLabel name="mobile_phone" label="Mobile Phone" />
            {type !== "embeded" ? (
              <YaoFieldPhoneInput
                name="mobile_phone"
                defaultCountry="gb"
                onlyCountries={["gb", "au"]}
                placeholder="Enter your mobile phone number"
                inputStyle={{ width: '100%' }}
              />
            ) : (
              <YAOFieldText
                name="mobile_phone"
                label=""
                placeholder="(***) **** ****"
                disabled={true}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6} marginTop={DEFAULT_SPACING}>
            <YaoFormFieldLabel name="home_phone" label="Home Phone" />
            {type !== "embeded" ? (
              <YAOFieldText
                name="home_phone"
                label=""
                placeholder="Enter your home phone number"
              />
            ) : (
              <YAOFieldText
                name="home_phone"
                label=""
                placeholder="(***) **** ****"
                disabled={true}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6} marginTop={DEFAULT_SPACING}>
            <YaoFormFieldLabel name="work_phone" label="Work Phone" />
            {type !== "embeded" ? (
              <YAOFieldText
                name="work_phone"
                label=""
                placeholder="Enter your work phone number"
              />
            ) : (
              <YAOFieldText
                name="work_phone"
                label=""
                placeholder="(***) **** ****"
                disabled={true}
              />
            )}
          </Grid>
          {isCompany && (
            <>
              <Grid item xs={12}>
                <YaoFormFieldLabel name="cc_emails" label="Other Emails" />
                <YAOFieldAutocompleteTagInput
                  name="cc_emails"
                  label=""
                  placeholder="Enter emails"
                  filterSelectedOptions={false}
                  options={[]}
                  textFieldType="email"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <YaoFormFieldLabel
                  name="company_number"
                  label="Company Number"
                />
                <YAOFieldText
                  name="company_number"
                  label=""
                  type="text"
                  placeholder="Enter your company number"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <YaoFormFieldLabel name="fax_number" label="Fax Number" />
                <YAOFieldText
                  name="fax_number"
                  label=""
                  type="text"
                  placeholder="Enter fax number"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <YaoFormFieldLabel name="website" label="Website" />
                <YAOFieldText
                  name="website"
                  label=""
                  type="text"
                  placeholder="Enter website"
                />
              </Grid>
              <Grid item xs={12} marginTop={DEFAULT_SPACING}>
                <YaoFormFieldLabel
                  name="beneficial_owner"
                  label="Beneficial Owner"
                />
                <YAOFieldText
                  name="beneficial_owner"
                  label=""
                  type="text"
                  placeholder="Enter beneficial owner"
                />
              </Grid>
              <Grid item xs={12}>
                <YaoFormFieldLabel name="directors" label="Directors" />
                <YAOFieldAutocompleteTagInput
                  name="directors"
                  label=""
                  placeholder="Enter directors"
                  options={[]}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} marginTop={DEFAULT_SPACING}>
            <YaoFormFieldLabel name="address_address_line_1" label="Street" />
            <TextField
              inputRef={ref}
              fullWidth
              placeholder="Enter address"
              InputProps={{
                sx: {
                  p: 0,
                },
              }}
              {...methods.register("address_address_line_1")}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <YaoFormFieldLabel name="address_address_line_2" label="Number" />
            <YAOFieldText
              name="address_address_line_2"
              label=""
              type="text"
              placeholder="Enter number"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <YaoFormFieldLabel name="address_city" label="Town" />
            <YAOFieldText
              name="address_city"
              label=""
              type="text"
              placeholder="Enter city"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <YaoFormFieldLabel name="address_postal_code" label="Postal code" />
            <YAOFieldText
              name="address_postal_code"
              label=""
              type="text"
              placeholder="Enter postal code"
            />
          </Grid>
          {type !== "embeded" && (
            <Grid item xs={12} marginTop={DEFAULT_SPACING}>
              <YaoFormFieldLabel name="tags" label="Contact type" />
              <YAOFieldAutocompleteTagInput
                name="tags"
                label=""
                placeholder="Enter tags"
                options={tags}
                filterSelectedOptions={true}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <YaoFormFieldLabel name="notes" label="Notes" />
            <YAOFieldText
              name="notes"
              label=""
              type="text"
              placeholder="Enter notes"
              minRows={2}
              maxRows={5}
              multiline={true}
            />
          </Grid>
          {!isCompany && type !== "embeded" && (
            <Grid item xs={12}>
              <YaoFormFieldLabel name="company" label="Company" />
              <Autocomplete
                id="company"
                isOptionEqualToValue={(
                  o: YAOFieldAutocompleteOptionObject,
                  v: YAOFieldAutocompleteOptionObject
                ) => o.value === v.value}
                filterOptions={(o) => o}
                options={
                  (companyApi.data || []).map((m: Contact) => ({
                    value: m._id,
                    label: m.company_name,
                  })) as YAOFieldAutocompleteOptionObject[]
                }
                loading={companyApi.loading}
                value={
                  methods.getValues(
                    "company"
                  ) as YAOFieldAutocompleteOptionObject
                }
                blurOnSelect={true}
                onChange={(
                  _: SyntheticEvent,
                  value: YAOFieldAutocompleteOptionObject | null
                ) => {
                  methods.setValue("company", value);
                }}
                inputValue={companyInputValue}
                onInputChange={(_: SyntheticEvent, value: string) => {
                  setCompanyInputValue(value);
                }}
                noOptionsText={
                  companyInputValue.length > 0
                    ? "No company found"
                    : "Type anything to search"
                }
                disableClearable
                forcePopupIcon={false}
                clearOnEscape={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select contact company"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password1989",
                    }}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      endAdornment: (
                        <>
                          {companyApi.loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                        </>
                      ),
                    }}
                  />
                )}
                getOptionLabel={(o: YAOFieldAutocompleteOptionObject) =>
                  o.label || ""
                }
              />
            </Grid>
          )}
        </Grid>
        <Stack direction="row" spacing={1} marginTop={DEFAULT_SPACING}>
          <Typography
            flexGrow={1}
            flexShrink={1}
            fontSize={18}
            fontWeight={600}
            color={palette.yao.primary[2]}
          >
            Additional Information
          </Typography>
          <Iconify
            onClick={() => setAdditionalInfoVisible(!additionalInfoVisible)}
            color={palette.yao.primary[2]}
            fontSize={20}
            sx={{ cursor: "pointer" }}
            icon={
              additionalInfoVisible ? "akar-icons:minus" : "akar-icons:plus"
            }
          />
        </Stack>
        {additionalInfoVisible && (
          <Grid
            container
            rowSpacing={1.5}
            columnSpacing={2.5}
            className="accordion-content"
          >
            {isCompany ? (
              <>
                <Grid item xs={12} md={6}>
                  <YaoFormFieldLabel name="company_utr" label="Company UTR" />
                  <YAOFieldText
                    name="company_utr"
                    label=""
                    type="text"
                    placeholder="Enter company UTR"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <YaoFormFieldLabel
                    name="company_web_filling_code"
                    label="Web filling code"
                  />
                  <YAOFieldText
                    name="company_web_filling_code"
                    label=""
                    type="text"
                    placeholder="Enter web filling code"
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} md={6}>
                  <YaoFormFieldLabel
                    name="national_insurance_number"
                    label="National Insurance Number"
                  />
                  <YAOFieldText
                    name="national_insurance_number"
                    label=""
                    type="text"
                    placeholder="Enter your national insurance number"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <YaoFormFieldLabel name="occupation" label="Occupation" />
                  <YAOFieldText
                    name="occupation"
                    label=""
                    type="text"
                    placeholder="i.e. lawyer, broker, lender, etc"
                  />
                </Grid>
                <Grid item xs={12} md={6} marginBottom={DEFAULT_SPACING}>
                  <YaoFormFieldLabel name="birth_date" label="Date of birth" />
                  <YAOFieldDatePicker
                    name="birth_date"
                    datePickerProps={{
                      views: ["year", "month", "day"],
                    }}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <YaoFormFieldLabel
                name="bank_account_account_name"
                label="Bank name"
              />
              <YAOFieldText
                name="bank_account_account_name"
                label=""
                type="text"
                placeholder="Enter bank name"
              />
            </Grid>
            <Grid item xs={6}>
              <YaoFormFieldLabel
                name="bank_account_account_sort_code"
                label="Sort code"
              />
              <YAOFieldText
                name="bank_account_account_sort_code"
                label=""
                type="text"
                placeholder="Enter sort code"
              />
            </Grid>
            <Grid item xs={6}>
              <YaoFormFieldLabel
                name="bank_account_account_number"
                label="Bank account number"
              />
              <YAOFieldText
                name="bank_account_account_number"
                label=""
                type="text"
                placeholder="Enter account number"
              />
            </Grid>
          </Grid>
        )}

        <Grid item xs={12} mt={2}>
          <Typography sx={{ fontSize: 11, color: palette.yao.primary[3] }}>
            <span style={{ color: palette.yao.secondary[2], fontWeight: 600 }}>
              *
            </span>{" "}
            Mandatory fields
          </Typography>
        </Grid>
        <Stack
          direction="row"
          sx={{
            mt: 4,
            justifyContent: "right",
          }}
          spacing={2}
        >
          {!hideCancel && (
            <Button color="secondary" onClick={handleCancel}>
              {cancelText}
            </Button>
          )}
          <Button
            variant="contained"
            sx={{ minWidth: 100 }}
            type="submit"
            onClick={methods.handleSubmit(onSubmit)}
          >
            {confirmText}
          </Button>
        </Stack>
      </FormProvider>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default ContactForm;
