import { createContext, FC, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import {
  FinancialStats,
  getMatter,
  getMatterFinancialLimit,
  Matter,
  MatterStatus,
  updateMatterStatus,
} from "../../../api/matters";
import useAuth from "src_common/hooks/useAuth";
import { Attorney, getAttorney } from "../../../api/attorneys";
import { listWorkflowTemplate, WorkflowList } from "../../../api/workflow";
import { FormTemplate, FormType } from "src_common/@interfaces/forms";
import { getFormTemplates } from "../../../api/forms";
import ComplianceService from "src_lawfirm/api/compliances";
import InterestService, { Interest } from "src_lawfirm/api/interests";

type QueryInvalidationKey =
  | "details"
  | "attorney"
  | "financial"
  | "workflow-template"
  | "forms-template"
  | "precedent-template"
  | "compliances";

type PropsMatterDetailsContext = {
  invalidate: (query: QueryInvalidationKey) => void;
  matterId: string;
  matter: UseQueryResult<Matter>;
  matterStatusMutation: UseMutationResult<Matter, unknown, MatterStatus>;
  attorney: UseQueryResult<Attorney>;
  financialData: UseQueryResult<FinancialStats | null>;
  workflowTemplates: UseQueryResult<WorkflowList[]>;
  formsTemplates: UseQueryResult<FormTemplate[]>;
  precedentTemplates: UseQueryResult<FormTemplate[]>;
  totalCompliances: number;
  interests: Interest[];
};

const DEFAULT_VALUES = {
  invalidate: (query: QueryInvalidationKey) => {},
  matterId: "",
  matter: undefined as any,
  matterStatusMutation: undefined as any,
  attorney: undefined as any,
  financialData: undefined as any,
  workflowTemplates: undefined as any,
  formsTemplates: undefined as any,
  precedentTemplates: undefined as any,
  totalCompliances: 0,
  interests: [],
};

const MatterDetailsContext =
  createContext<PropsMatterDetailsContext>(DEFAULT_VALUES);

const MatterDetailsContextProvider: FC = ({ children }) => {
  const { matterId = "" } = useParams();
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const { data: interests } = useQuery(["interests"], {
    queryFn: () => {
      const interestService = new InterestService();
      return interestService.findAll();
    },
    staleTime: 1000 * 60 * 2,
  });

  const matter = useQuery(
    ["matter-details", matterId],
    () => getMatter(matterId),
    {
      enabled: !!matterId,
    }
  );

  const matterStatusMutation = useMutation(
    (status: MatterStatus) => updateMatterStatus({ id: matterId, status }),
    {
      onSuccess: () => {
        invalidate("details");
      },
    }
  );

  const getTotalCompliances = useCallback(async () => {
    const complianceService = new ComplianceService();
    if (matterId) {
      const response = await complianceService.countMatterPendingCompliances(
        matterId
      );
      return response.data;
    }
    return 0;
  }, [matterId]);

  const { data: totalCompliances } = useQuery(
    ["total-compliances", matterId],
    () => getTotalCompliances(),
    { enabled: !!matterId, cacheTime: 15000 }
  );

  const attorney = useQuery(
    ["matter-attorney", user?._id],
    () => getAttorney(user?._id as string),
    {
      enabled: !!user,
    }
  );

  const financialData = useQuery(
    ["matter-financial", matterId],
    () => getMatterFinancialLimit(matterId),
    {
      enabled: !!matterId,
      cacheTime: 30000,
    }
  );

  const workflowTemplates = useQuery(
    ["matter-workflow-templates"],
    () => listWorkflowTemplate(),
    {
      enabled: !!matterId,
      cacheTime: 30000,
    }
  );

  const formsTemplates = useQuery(
    ["matter-forms-templates"],
    () =>
      getFormTemplates({ form_type: FormType.FORM, backoffice_forms: true }),
    {
      enabled: !!matterId,
      cacheTime: 30000,
    }
  );

  const precedentTemplates = useQuery(
    ["matter-precedent-templates"],
    () =>
      getFormTemplates({
        form_type: FormType.PRECEDENT,
        backoffice_forms: true,
      }),
    {
      enabled: !!matterId,
      cacheTime: 30000,
    }
  );

  const invalidate = (query: QueryInvalidationKey) => {
    switch (query) {
      case "details":
        queryClient.invalidateQueries(["matter-details", matterId]);
        break;
      case "attorney":
        queryClient.invalidateQueries(["matter-attorney", user?._id]);
        break;
      case "workflow-template":
        queryClient.invalidateQueries(["matter-workflow-templates"]);
        break;
      case "forms-template":
        queryClient.invalidateQueries(["matter-forms-templates"]);
        break;
      case "precedent-template":
        queryClient.invalidateQueries(["matter-precedent-templates"]);
        break;
      case "compliances":
        queryClient.invalidateQueries(["total-compliances"]);
        break;
    }
  };

  return (
    <MatterDetailsContext.Provider
      value={{
        invalidate,
        matterId,
        matter,
        matterStatusMutation,
        attorney,
        financialData,
        workflowTemplates,
        formsTemplates,
        precedentTemplates,
        totalCompliances: totalCompliances || 0,
        interests: interests || [],
      }}
    >
      {children}
    </MatterDetailsContext.Provider>
  );
};

export { MatterDetailsContext, MatterDetailsContextProvider };
