import NiceModal, { useModal } from "@ebay/nice-modal-react"
import { Dialog } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { CaseType } from "src_lawfirm/api/case-types"
import CaseTypeForm from "./CaseTypeForm"
import { Department } from "src_lawfirm/api/departments"

type DialogProps = {
  case_type?: CaseType
  departments?: Department[]
}

const CaseTypeDialog = NiceModal.create<DialogProps>(({ case_type, departments }) => {
  const modal = useModal()
  const [type, setType] = useState<"create" | "update">("create")

  useEffect(() => {
    if (case_type !== null && case_type !== undefined) {
      setType("update")
    }
  }, [case_type])

  const handleCloseForm = useCallback(() => {
    modal.hide()
  }, [modal])

  const handleFinishForm = (created: CaseType) => {
    modal.resolve(created)
    modal.hide()
  }

  return (
    <>
      <Dialog
        maxWidth="md"
        open={modal.visible}
        onClose={handleCloseForm}
        TransitionProps={{
          onExited: () => modal.remove()
        }}
      >
        <div style={{ padding: "2rem" }}>
          <CaseTypeForm
            type={type}
            case_type={case_type}
            onCancel={handleCloseForm}
            onFinish={handleFinishForm}
            confirmText={type === "create" ? "Add" : "Save"}
            departments={departments || []}
          />
        </div>
      </Dialog>
    </>
  )
})

export default CaseTypeDialog;