import axios from "src_common/utils/axios";
import { formatError } from "src_common/utils/misc";

export type Department = {
  _id: string,
  title: string,
  short_description: string,
  long_description: string
}

export interface DepartmentQueryRequest {
  title?: string;
  limit?: number;
  page?: number;
}

export type DepartmentFormData = {
  title: string
  short_description: string | undefined
}

export async function getDepartments(
  params: DepartmentQueryRequest = {},
  incrementPage = false
): Promise<Department[]> {
  const { data: response } = await axios.get<Department[]>("departments", {
    params: incrementPage ? { ...params, page: Number(params.page) + 1 } : params,
  })
  return response
}

export async function getDepartment(id: string): Promise<Department> {
  const { data: response } = await axios.get<Department>(`/departments/${id}`)
  return response
}

export async function createDepartment(form: DepartmentFormData): Promise<Department> {
  try {
    const { data: response } = await axios.post<Department>("departments", form)
    return response
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function updateDepartment(id: string, form: DepartmentFormData): Promise<Department> {
  try {
    const { data: response } = await axios.patch<Department>(`/departments/${id}`, form)
    return response
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function deleteDepartment(id: string): Promise<void> {
  try {
    await axios.patch(`/departments/${id}/delete`, {
      status: true
    })
  } catch (e) {
    throw new Error(formatError(e));
  }
}
