import { AxiosResponse, CancelToken } from "axios";
import axios from "src_common/utils/axios";
import { wait } from "src_common/utils/misc";
import { Contact } from "./contacts";
import { HistoryItem } from "../../src_common/components/notifications/NotificationContext";
import { Task } from "./tasks";

export enum ContactCategory {
  CLIENT = "CLIENT",
  LENDER = "LENDER",
  BROKER = "BROKER",
  ESTATE_AGENT = "ESTATE_AGENT",
  COUNSEL = "COUNSEL",
  SOLICITOR = "SOLICITOR",
  EXPERT = "EXPERT",
  INSURER = "INSURER",
  LANDLORD = "LANDLORD",
  MANAGEMENT_COMPANY = "MANAGEMENT_COMPANY",
  LOCAL_AUTHORITY = "LOCAL_AUTHORITY",
  OTHER_PARTY = "OTHER_PARTY",
  TENANT = "TENANT",
  SEARCH_PROVIDER = "SEARCH_PROVIDER",
  COURT = "COURT",
  OTHER = "OTHER",
}

export const ContactCategoryLabel: Record<ContactCategory, string> = {
  [ContactCategory.CLIENT]: "Client",
  [ContactCategory.LENDER]: "Lender",
  [ContactCategory.BROKER]: "Broker",
  [ContactCategory.ESTATE_AGENT]: "Estate Agent",
  [ContactCategory.COUNSEL]: "Counsel",
  [ContactCategory.SOLICITOR]: "Solicitor",
  [ContactCategory.EXPERT]: "Expert",
  [ContactCategory.INSURER]: "Insurer",
  [ContactCategory.LANDLORD]: "Landlord",
  [ContactCategory.MANAGEMENT_COMPANY]: "Management Company",
  [ContactCategory.LOCAL_AUTHORITY]: "Local Authority",
  [ContactCategory.OTHER_PARTY]: "Other Party",
  [ContactCategory.TENANT]: "Tenant",
  [ContactCategory.SEARCH_PROVIDER]: "Search Provider",
  [ContactCategory.COURT]: "Court",
  [ContactCategory.OTHER]: "Other",
};

export enum Source {
  GOOGLE = "GOOGLE",
  EXISTING_CLIENT = "EXISTING_CLIENT",
  INTERNAL_REFERRAL = "INTERNAL_REFERRAL",
  LOCAL_NEWSPAPER = "LOCAL_NEWSPAPER",
  NATIONAL_NEWSPAPER = "NATIONAL_NEWSPAPER",
  NETWORKING_EVENT = "NETWORKING_EVENT",
  ACCOUNTANT_REFERRAL = "ACCOUNTANT_REFERRAL",
  LAW_FIRM_REFERRAL = "LAW_FIRM_REFERRAL",
  CLIENT_REFERRAL = "CLIENT_REFERRAL",
  ESTATE_AGENT_REFERRAL = "ESTATE_AGENT_REFERRAL",
  SOCIAL_MEDIA = "SOCIAL_MEDIA",
  WORD_OF_MOUTH = "WORD_OF_MOUTH",
  OTHER = "OTHER",
}

export const SourceLabel: Record<Source, string> = {
  [Source.GOOGLE]: "Google or Google Ad",
  [Source.EXISTING_CLIENT]: "Existing Client",
  [Source.INTERNAL_REFERRAL]: "Internal referral from another department",
  [Source.LOCAL_NEWSPAPER]: "Local Newspaper",
  [Source.NATIONAL_NEWSPAPER]: "National Newspaper",
  [Source.NETWORKING_EVENT]: "Networking event",
  [Source.ACCOUNTANT_REFERRAL]: "Referral from accountant",
  [Source.LAW_FIRM_REFERRAL]: "Referral from another law firm",
  [Source.CLIENT_REFERRAL]: "Referral from Client",
  [Source.ESTATE_AGENT_REFERRAL]: "Referral from estate agent",
  [Source.SOCIAL_MEDIA]: "Social media",
  [Source.WORD_OF_MOUTH]: "Word of Mouth",
  [Source.OTHER]: "Other",
};

export enum MatterStatus {
  IN_PROGRESS = "IN_PROGRESS",
  QUOTE = "QUOTE",
  ON_HOLD = "ON_HOLD",
  COMPLETED = "COMPLETED",
  EXCHANGED = "EXCHANGED",
  ARCHIVED = "ARCHIVED",
  NOT_PROCEEDING = "NOT_PROCEEDING",
}

export const MatterStatusLabel: Record<MatterStatus, string> = {
  [MatterStatus.IN_PROGRESS]: "In Progress",
  [MatterStatus.QUOTE]: "Quote",
  [MatterStatus.ON_HOLD]: "On Hold",
  [MatterStatus.COMPLETED]: "Completed",
  [MatterStatus.EXCHANGED]: "Exchanged",
  [MatterStatus.ARCHIVED]: "Archived",
  [MatterStatus.NOT_PROCEEDING]: "Not Proceeding",
};

export const Status = {
  QUOTE: { value: "QUOTE", name: "Quote" },
  IN_PROGRESS: { value: "IN_PROGRESS", name: "In progress" },
  ON_HOLD: { value: "ON_HOLD", name: "On hold" },
  COMPLETED: { value: "COMPLETED", name: "Completed" },
  EXCHANGED: { value: "EXCHANGED", name: "Exchanged" },
  ARCHIVED: { value: "ARCHIVED", name: "Archived" },
  NOT_PROCEEDING: { value: "NOT_PROCEEDING", name: "Not Proceeding" },
};

export type MatterBalance = {
  wip: number;
  total_invoiced: number;
  unpaid_invoices: number;
  financial_budget: number;
};

export type MatterArchiveStatsResponse = {
  open_tasks: Array<Pick<Task, "_id" | "title" | "status" | "category">>;
  wip_time_entries: {
    billable: number;
    count: number;
  };
  accounts_balance: {
    client_account_balance: number;
    office_account_balance: number;
  };
  pending_compliances: number;
};

export type MatterProgress = {
  id: number;
  title: string;
  assignee: string;
  created_at: Date;
  is_current?: boolean;
}[];

export async function getMatter(id: number | string) {
  const res: AxiosResponse<Matter> = await axios.get(`/matters/${id}`);

  return res.data;
}

export type MattersApiResponse = {
  limit: number;
  search: string;
  rows: Matter[];
};

export async function getMatters(
  params:
    | {
        responsible_lawyer?: string | undefined;
        status?: string | undefined;
        search?: string | undefined;
        limit?: number | undefined;
        last_id?: string | undefined;
        case_type?: string | undefined;
        ids?: string[];
        contact?: string | undefined;
        title?: string | undefined;
        number?: number | undefined;
        page?: number | undefined;
      }
    | undefined = undefined,
  cancelToken: CancelToken | undefined = undefined
): Promise<Matter[]> {
  const res: AxiosResponse<MattersApiResponse> = await axios.get("/matters", {
    params,
    cancelToken: cancelToken,
  });
  return (res.data?.rows as Matter[]) || [];
}

export async function getLastAccessedMatters(): Promise<Matter[]> {
  const res: AxiosResponse<MattersApiResponse> = await axios.get(
    "/matters/last-accessed"
  );
  return (res.data?.rows as Matter[]) || [];
}

export async function getMatterOverview(id: number) {
  await wait(1000);

  return {
    progress: [
      {
        id: 1,
        title: "Matter started",
        assignee: "Richard Nelson",
        created_at: new Date(2023, 0, 1, 10, 28),
      },
      {
        id: 2,
        title: "Matter completed",
        assignee: "Richard Pryor",
        created_at: new Date(2023, 0, 3, 9, 18),
      },
      {
        id: 3,
        title: "Matter closed",
        assignee: "John",
        created_at: new Date(2023, 0, 10, 12, 34),
        is_current: true,
      },
    ],
  };
}

export async function getArchiveStats(
  id: string
): Promise<MatterArchiveStatsResponse> {
  const res = await axios.get<MatterArchiveStatsResponse>(
    `/matters/${id}/archive-stats`
  );

  return res.data;
}

export async function writeOff(id: string): Promise<boolean> {
  const res = await axios.patch<boolean>(`/matters/${id}/write-off`);
  return res.data;
}

export async function closeAllTasks(id: string): Promise<boolean> {
  const res = await axios.patch<boolean>(`/matters/${id}/close-tasks`);
  return res.data;
}

export async function createMatter(params: MatterParams): Promise<Matter> {
  const res: AxiosResponse<Matter> = await axios.post("/matters", params);

  return res.data;
}

export async function updateMatter(
  id: string,
  params: MatterParams
): Promise<Matter> {
  const res: AxiosResponse<Matter> = await axios.put(`/matters/${id}`, params);

  return res.data;
}

export async function updateMatterStatus({
  id,
  status,
}: {
  id: string;
  status: MatterStatus;
}): Promise<Matter> {
  const res: AxiosResponse<Matter> = await axios.patch(
    `/matters/${id}/status`,
    { status }
  );

  return res.data;
}

export async function archiveMatter(id: string): Promise<Matter> {
  const res: AxiosResponse<Matter> = await axios.patch(
    `/matters/${id}/status`,
    { status: MatterStatus.ARCHIVED }
  );

  return res.data;
}

export async function unarchiveMatter(id: string): Promise<Matter> {
  const res: AxiosResponse<Matter> = await axios.patch(
    `/matters/${id}/unarchive`
  );

  return res.data;
}

export type MatterParams = {
  case_name: string;
  rate?: number | null;
  source?: Source;
  clients: Array<{ contact: string }>;
  responsible_lawyer: string;
  responsible_supervisor: string;
  paralegal?: string;
  secretary?: string;
  department: string;
  case_type: string;
  workflow?: string;
  interest_rate?: string;
  financial_limit: number;
  instructed_date?: Date;
  completed_date?: Date;
  archived_date?: Date;
  case_contacts: Array<{
    category: ContactCategory;
    contact: string;
    reference?: string;
  }>;
  client_letter_subject: string;
  other_party_letter_subject: string;
  permissions: string[];
  private: boolean;
};

export type Matter = {
  _id: string;
  id: string;
  number: number;
  rate: number;
  fee_earner: any;
  law_firm: any;
  client_account_balance: number;
  office_account_balance: number;
  status: string;
  case_name: string;
  clients: Array<{
    contact: Partial<Contact>;
  }>;
  responsible_lawyer: {
    _id: string;
    id: string;
    name: string;
    surname: string;
    email: string;
  };
  responsible_supervisor: any;
  paralegal: any;
  secretary: any;
  department: any;
  case_type: {
    _id: string;
    title: string;
  };
  workflow: any;
  billing_preferences: any;
  financial_limit: 0;
  instructed_date: string;
  interest_rate?: string;
  completed_date: string;
  archived_date: string;
  case_contacts: Array<{
    category: ContactCategory;
    contact: Partial<Contact>;
    reference: string;
    _id: string;
  }>;
  source?: any;
  client_letter_subject: string;
  other_party_letter_subject: string;
  permissions: string[];
  private?: boolean;
  created_at: string;
  updated_at: string;
};

export type FinancialStats = {
  limit_percentage: number;
  financial_limit: number;
  limit_consumed: number;
  total_invoiced: number;
  wip: number;
  outstanding_invoices: number;
};

export async function getMatterFinancialLimit(
  id: string
): Promise<FinancialStats | null> {
  const res: AxiosResponse<FinancialStats> = await axios.get(
    `/matters/${id}/financial-stats`
  );
  return res?.data || null;
}

export async function getMatterHistory(
  id: string,
  page: number,
  size: number
): Promise<HistoryItem[]> {
  const res: AxiosResponse<HistoryItem[]> = await axios.get(
    `/matters/${id}/history`,
    {
      params: {
        page,
        size,
      },
    }
  );
  return res?.data || [];
}
