import axios from "src_common/utils/axios";
import { formatError } from "src_common/utils/misc";

export async function getCasetype(department_id: string | undefined): Promise<CaseType[]> {
  const res = await axios.get(`case-types`, {
    params: {
      department_id,
    },
  });

  return res.data;
}

export type CaseType = {
  _id: string;
  title: string;
  short_description: string;
  long_description: string;
  law_firm: string;
  departments: string[];
  is_deleted: boolean;
  created_at: Date;
  updated_at: Date;
  department?: { _id: string; title: string }
};

export interface CaseTypeQueryRequest {
  title?: string;
  department_id?: string;
  limit?: number;
  page?: number;
}

export type CaseTypeFormData = {
  title: string
  short_description: string | undefined
  department: string
}

export async function getActiveCaseTypes(): Promise<CaseType[]> {
  const res = await axios.get("/case-types/active");
  return res.data;
}

export async function getCaseTypes(
  params: CaseTypeQueryRequest = {},
  incrementPage = false
): Promise<CaseType[]> {
  const { data: response } = await axios.get<CaseType[]>("case-types", {
    params: incrementPage ? { ...params, page: Number(params.page) + 1 } : params,
  })
  return response
}

export async function getCaseType(id: string): Promise<CaseType> {
  const { data: response } = await axios.get(`/case-types/${id}`)
  return response
}

export async function createCaseType(form: CaseTypeFormData): Promise<CaseType> {
  try {
    const { data: response } = await axios.post<CaseType>("case-types", form)
    return response
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function updateCaseType(id: string, form: CaseTypeFormData): Promise<CaseType> {
  try {
    const { data: response } = await axios.patch<CaseType>(`/case-types/${id}`, form)
    return response
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function deleteCaseType(id: string): Promise<void> {
  try {
    await axios.patch(`/case-types/${id}/delete`, {
      status: true
    })
  } catch (e) {
    throw new Error(formatError(e));
  }
}
