import axios from "src_common/utils/axios";

export interface Interest {
  _id: string;
  law_firm: string;
  title: string;
  description?: string;
  value: number;
  deleted: boolean;
  created_at: Date;
  updated_at: Date;
}

export interface InterestDto {
  title: string;
  description?: string;
  value: number;
}

export interface InterestCalculatorDto {
  matter: string;
  to_date?: Date;
}

export type InterestCalculatorResponse = {
  accrued_interest: number;
  paid_interest: number;
  balance: number;
};

class InterestService {
  private axiosInstance = axios;
  private baseUrl = "interests";

  async findAll(): Promise<Interest[]> {
    const response = await this.axiosInstance.get<Interest[]>(this.baseUrl);
    return response.data;
  }

  async calculateInterest(
    payload: InterestCalculatorDto
  ): Promise<InterestCalculatorResponse> {
    const response = await this.axiosInstance.post<InterestCalculatorResponse>(
      `${this.baseUrl}/calculate`,
      payload
    );
    return response.data;
  }

  async create(payload: InterestDto): Promise<Interest> {
    const response = await this.axiosInstance.post<Interest>(
      this.baseUrl,
      payload
    );
    return response.data;
  }

  async update(id: string, payload: InterestDto): Promise<Interest> {
    const response = await this.axiosInstance.put<Interest>(
      `${this.baseUrl}/${id}`,
      payload
    );
    return response.data;
  }

  async delete(id: string): Promise<Interest> {
    const response = await this.axiosInstance.delete<Interest>(
      `${this.baseUrl}/${id}`
    );
    return response.data;
  }
}

export default InterestService;
