import { formatError } from "src_common/utils/misc";
import axios from "src_common/utils/axios";
import { BankAccountTypes } from "./bank-account";
import { AxiosResponse } from "axios";
import Decimal from "decimal.js";
import { PaymentNotificationFormData } from "./payment-notifications";

Decimal.set({ precision: 10, rounding: 2 });

export enum LedgerEntryStatus {
  UNRECONCILED = "UNRECONCILED",
  RECONCILED = "RECONCILED",
  REVERSED = "REVERSED",
  REVERSE_RECONCILED = "REVERSE_RECONCILED",
  ANTICIPATED = "ANTICIPATED",
  REJECTED = "REJECTED",
}

export enum LedgerEntryType {
  OFFICE_RECEIPT = "OFFICE_RECEIPT",
  CLIENT_RECEIPT = "CLIENT_RECEIPT",
  OFFICE_PAYMENT = "OFFICE_PAYMENT",
  CLIENT_PAYMENT = "CLIENT_PAYMENT",
  CLIENT_TO_OFFICE = "CLIENT_TO_OFFICE",
  OFFICE_TO_CLIENT = "OFFICE_TO_CLIENT",
  CLIENT_TRANSFER = "CLIENT_TRANSFER",
  INVOICE = "INVOICE",
  OFFICE_CREDIT = "OFFICE_CREDIT",
  REVERSAL = "REVERSAL",
  INTEREST = "INTEREST",
}

export const LedgerEntryTypeLabel: Record<
  LedgerEntryType,
  { abbr: string; text: string }
> = {
  [LedgerEntryType.OFFICE_RECEIPT]: { abbr: "OR", text: "Office Receipt" },
  [LedgerEntryType.CLIENT_RECEIPT]: { abbr: "CR", text: "Client Receipt" },
  [LedgerEntryType.OFFICE_PAYMENT]: { abbr: "OP", text: "Office Payment" },
  [LedgerEntryType.CLIENT_PAYMENT]: { abbr: "CP", text: "Client Paument" },
  [LedgerEntryType.CLIENT_TO_OFFICE]: { abbr: "CTO", text: "Client To Office" },
  [LedgerEntryType.OFFICE_TO_CLIENT]: { abbr: "OTC", text: "Office To Client" },
  [LedgerEntryType.CLIENT_TRANSFER]: { abbr: "CT", text: "Client Transfer" },
  [LedgerEntryType.INVOICE]: { abbr: "I", text: "Invoice" },
  [LedgerEntryType.OFFICE_CREDIT]: { abbr: "OC", text: "Office Credit" },
  [LedgerEntryType.REVERSAL]: { abbr: "R", text: "Reversal" },
  [LedgerEntryType.INTEREST]: { abbr: "IN", text: "Interest" },
};

export const LedgerEntryStatusDetails = {
  REVERSED: { name: "Reversed", color: "primary" },
  UNRECONCILED: { name: "Unreconciled", color: "primary" },
  RECONCILED: { name: "Reconciled", color: "success" },
  REVERSE_RECONCILED: { name: "Reverse reconciled", color: "success" },
  ANTICIPATED: { name: "Anticipated", color: "warning" },
  REJECTED: { name: "Rejected", color: "error" },
};
export interface SuccessResponse {
  success: boolean;
}

export interface LedgerFormData {
  notes: string;
  matter: string;
  bank_account: string;
  value: number;
  date: string;
}

export interface LedgerAnticipatedDisbursementFormData {
  date: string;
  account_type: string;
  payee: string;
  notes: string;
  reference: string;
  matter: {
    _id: string;
    title: string;
    number: number;
  };
  bank_account: {
    _id: string;
    name: string;
  };
  author: {
    _id: string;
    name: string;
  };
  vat: number;
  value: number;
  subtotal: number;
}

export interface SearchLedger {
  page: number;
  size: number;
  matter?: string;
  bank_account?: string;
  type?: BankAccountTypes | null;
  ledger_type?: LedgerEntryType;
  status?: LedgerEntryStatus;
  start?: Date | null;
  end?: Date | null;
  negative?: boolean;
  reconciliable?: boolean;
  reconciliation?: string;
  invoice?: string;
  value?: number | undefined;
}

export interface Ledger {
  _id: string;
  notes: string;
  vat: number;
  vat_percentage: number;
  subtotal: number;
  value: number;
  balance: number;
  date: string;
  status: LedgerEntryStatus;
  matter: {
    _id: string;
    title: string;
    number: number;
  };
  author: {
    _id: string;
    name: string;
  };
  account_type: BankAccountTypes;
  bank_account: {
    _id: string;
    name: string;
  };
  created_at: string;
  locked: boolean;
  loading?: boolean;
  invoice?: string;
  reference?: string;
  outstanding?: number;
  law_firm?: string;
  paid?: number;
  type?: LedgerEntryType;
  updated_at?: string;
  reversed_id?: string;
  payee?: string;
}

export async function createLedger(
  form: PaymentNotificationFormData
): Promise<LedgerFormData> {
  try {
    const res = await axios.post("ledgers", form);
    return res.data;
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function getLedger(ledgerId: string): Promise<Ledger> {
  try {
    const resp: AxiosResponse<Ledger> = await axios.get(`/ledgers/${ledgerId}`);
    return resp?.data || "";
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function updateLedger(
  ledgerId: string,
  form: PaymentNotificationFormData
): Promise<SuccessResponse> {
  try {
    const resp: AxiosResponse<SuccessResponse> = await axios.patch(
      `/ledgers/${ledgerId}`,
      form
    );
    return resp?.data || "";
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function searchLedgers(search: SearchLedger): Promise<Ledger[]> {
  try {
    const resp: AxiosResponse<Ledger[]> = await axios.post(
      "ledgers/search",
      search
    );
    return resp?.data || [];
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function reverseLedger(
  id: string,
  date: Date
): Promise<SuccessResponse> {
  try {
    return await axios.post(`ledgers/${id}/reverse`, {
      date,
    });
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function getDisbursementsTotal({
  matter,
  start = null,
  end = null,
}: {
  matter: string;
  start: Date | null;
  end: Date | null;
}): Promise<any> {
  const rows = await searchLedgers({
    page: 0,
    size: 10000,
    matter,
    type: BankAccountTypes.OFFICE,
    start,
    end,
  });
  return rows
    .filter(
      (r) =>
        !`${r.invoice || ""}`.length &&
        [LedgerEntryStatus.ANTICIPATED, LedgerEntryStatus.REJECTED].indexOf(
          r.status
        ) === -1 &&
        r.type !== LedgerEntryType.REVERSAL
    )
    .map((r) => new Decimal(r.subtotal || r.value || 0))
    .reduce((p, c) => p.plus(c), new Decimal(0))
    .toNumber();
}

export async function allocateAntecipated(
  id: string
): Promise<SuccessResponse> {
  try {
    return await axios.patch(`ledgers/${id}/allocate`, {});
  } catch (e) {
    throw new Error(formatError(e));
  }
}

export async function rejectAntecipated(id: string): Promise<SuccessResponse> {
  try {
    return await axios.patch(`ledgers/${id}/reject`, {});
  } catch (e) {
    throw new Error(formatError(e));
  }
}
