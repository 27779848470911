import NiceModal from "@ebay/nice-modal-react";
import { Button, Container, FormControl, IconButton, Stack, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import CustomIcon from "src_common/components/custom-icon";
import Iconify from "src_common/components/Iconify";
import Page from "src_common/components/Page";
import YaoAutocomplete from "src_common/components/yao-form/YaoAutocomplete";
import YaoTable from "src_common/components/yao-table";
import { useAPI } from "src_common/hooks/useAPI";
import useDebounce from "src_common/hooks/useDebounce";
import { useInfiniteScrollQuery } from "src_common/hooks/useInfiniteScrollQuery";
import palette from "src_common/theme/palette";
import { CaseType, CaseTypeQueryRequest, deleteCaseType, getCaseTypes } from "src_lawfirm/api/case-types";
import { getDepartments } from "src_lawfirm/api/departments";
import _ from "lodash"
import CaseTypeDialog from "./CaseTypeDialog";
import { fDate } from "src_common/utils/formatTime";

export const defaultFilters: CaseTypeQueryRequest = {
  department_id: undefined,
  title: '',
  limit: 20,
  page: 0,
}

export default function CaseTypesTab() {
  const { debounce } = useDebounce()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const [filters, setFilters] = useState<CaseTypeQueryRequest>(defaultFilters)
  const [inputValue, setInputValue] = useState<string>("")
  const [inputDepartmentValue, setInputDepartmentValue] = useState<string>("")

  const getDepartmentsAPI = useAPI(getDepartments)

  useEffect(() => {
    getDepartmentsAPI.invoke()
  }, [])

  const caseTypesQuery = useInfiniteQuery(
    ["case-types", filters],
    ({ pageParam = 0 }) => getCaseTypes({
      ...filters,
      page: pageParam
    }),
    {
      getNextPageParam: (lastPage, allPages) =>
        Array.isArray(lastPage) && !lastPage.length
          ? undefined
          : allPages.length
    }
  )

  const caseTypesInfiniteQuery = useInfiniteScrollQuery({
    fetch:
      !caseTypesQuery.isFetching && caseTypesQuery.hasNextPage
        ? caseTypesQuery.fetchNextPage
        : undefined,
  })

  const refetchQuery = () => {
    queryClient.invalidateQueries(["case-types", filters])
  }

  const rows = useMemo(() => {
    return (caseTypesQuery.data?.pages || [])
      .flatMap((page) => page)
  }, [caseTypesQuery.data])

  const updateFilter = (key: keyof CaseTypeQueryRequest, value: any) => {
    setFilters((prev) => ({ ...prev, [key]: value }))
  }

  const handleTitleFilter = (value: string) => {
    updateFilter("title", value.trim())
  }

  const handleDepartmentFilter = (v: any) => {
    updateFilter("department_id", v?.value || undefined)
  }

  useEffect(() => {
    debounce(() => {
      handleTitleFilter(inputValue)
    }, 500)
  }, [inputValue])

  const handleAddCaseType = () => {
    NiceModal.show(CaseTypeDialog, {
      departments: getDepartmentsAPI.data || [],
    }).then(() => {
      refetchQuery()
    })
  }

  const handleEditCaseType = (case_type: CaseType) => {
    NiceModal.show(CaseTypeDialog, {
      case_type,
      departments: getDepartmentsAPI.data || [],
    }).then(() => {
      refetchQuery()
    })
  }

  const handleRemoveCaseType = async (case_type: CaseType) => {
    try {
      await deleteCaseType(case_type._id)
      enqueueSnackbar(`Case type ${case_type.title} removed`)
      refetchQuery()
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" })
    }
  }

  const TableToolbar = ({
    inputValue,
    setInputValue,
  }: any) => (
    <>
      <FormControl>
        <YaoAutocomplete
          renderInput={(params: any) => (
            <TextField
              {...params}
              label="Department"
              style={{ width: "20rem" }}
            />
          )}
          id="department-label"
          name="department-label"
          placeholder="Select a department"
          options={Object.values(
            getDepartmentsAPI.data || []
          ).map((department: any) => ({
            label: department.title,
            value: department._id,
          }))}
          onChange={(_: any, v: any) => handleDepartmentFilter(v)}
        />
      </FormControl>
      <FormControl>
        <TextField
          id="title-search"
          label="Search by title"
          variant="outlined"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value)
          }}
          InputProps={{
            sx: {
              p: 0,
              padding: 0,
              width: "20rem",
              height: "2.5rem"
            }
          }}
        />
      </FormControl>
      {(inputValue.length > 0) && (
        <Tooltip title="Clear title" style={{ height: "2.5rem" }}>
          <IconButton
            onClick={() => {
              setInputValue("")
            }}
          >
            <Iconify icon="cil:filter-x" />
          </IconButton>
        </Tooltip>
      )}
    </>
  )

  const columns = [
    {
      id: "title",
      label: "title",
      formatter: (__: any, row: CaseType) => (_.capitalize(row?.title)),
    },
    {
      id: "short_description",
      label: "description",
      formatter: (__: any, row: CaseType) => {
        return (
          <Typography noWrap={true} maxWidth={250} title={row?.short_description}>
            {row?.short_description || "-"}
          </Typography>
        )
      },
    },
    {
      id: "department",
      label: "department",
      formatter: (__: any, row: CaseType) => (_.capitalize(row?.department?.title))
    },
    {
      id: "created_at",
      label: "created at",
      formatter: fDate
    },
    {
      id: "",
      label: "",
      sx: { width: "1rem" },
    }
  ]

  return (
    <Page title="Case Types" sx={{ height: "100%" }}>
      <Container maxWidth={false} sx={{ height: "100%" }}>
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <Button
            startIcon={
              <CustomIcon
                name="add"
                sx={{ padding: 0.2 }}
                color={palette.yao.grey[6]}
              />
            }
            variant="outlined"
            onClick={() => handleAddCaseType()}
          >
            Case Type
          </Button>
        </Stack>
        <Stack direction="row" justifyContent={"space-between"} alignItems={"center"} sx={{ marginTop: "0.5rem" }}>
          <Typography sx={{ fontSize: 18, fontWeight: 600 }}>Case Types</Typography>
          <Stack direction="row" spacing={1} justifyContent={"flex-end"} alignItems={"center"}>
            {TableToolbar({ inputValue, setInputValue, inputDepartmentValue, setInputDepartmentValue })}
          </Stack>
        </Stack>
        <YaoTable
          isLoading={caseTypesQuery.isLoading}
          isPagging={caseTypesQuery.isFetching}
          afterRows={() => (
            <>
              <TableRow ref={caseTypesInfiniteQuery.ref} style={{ height: 0 }} />
            </>
          )}
          labelNoData="No case types found"
          imgNoData="/assets/illustrations/illustration_empty_content.svg"
          rows={rows}
          columns={columns as any}
          rowOptions={[
            {
              action: handleEditCaseType,
              label: "Edit",
              icon: "eva:edit-fill"
            },
            {
              action: handleRemoveCaseType,
              label: "Remove",
              icon: "eva:trash-2-outline"
            }
          ]}
        />
      </Container>
    </Page>
  )
}